import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "native-base";
export default class Header extends Component {
  render() {
    return (
      <View style={styles.headerView}>
        <Text style={styles.headerMainText}>{this.props.title}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerView: {
    height: 54,
    paddingLeft: 15,
    paddingBottom: 7
  },
  headerMainText: {
    fontSize: 34,
    textAlign: "left",
    fontWeight: "800"
  }
});
