import * as Expo from "expo";
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { createStackNavigator, createAppContainer } from "react-navigation";
import { createBottomTabNavigator, BottomTabBar } from "react-navigation-tabs";
import {
  Root,
  Footer,
  FooterTab,
  Icon,
  Button,
  Text,
  StyleProvider
} from "native-base";

import HomeScreen from "./screens/home";
import DictationScreen from "./screens/dictation";
import SettingsScreen from "./screens/settings";
import VideoFilesScreen from "./screens/settings/video_files";

import getTheme from "./native-base-theme/components";
import platform from "./native-base-theme/variables/platform";

const HomeStack = createStackNavigator({
  Home: {
    screen: HomeScreen
  },
  Dictation: {
    screen: DictationScreen
  }
});

HomeStack.navigationOptions = ({ navigation }) => {
  let tabBarVisible = true;
  if (navigation.state.index === 1) {
    tabBarVisible = false;
  }

  return {
    tabBarVisible
  };
};

const SettingsStack = createStackNavigator({
  Settings: {
    screen: SettingsScreen
  },
  VideoFiles: {
    screen: VideoFilesScreen
  }
});

const TabNavigator = createBottomTabNavigator(
  {
    Home: HomeStack,
    Settings: SettingsStack
  },
  {
    tabBarComponent: props => {
      return (
        <StyleProvider style={getTheme(platform)}>
          <Footer
            style={Platform.OS === "web" ? webStyles.footer : styles.footer}
          >
            <FooterTab style={{ backgroundColor: "#ffffff" }}>
              <Button
                active={props.navigation.state.index === 0}
                onPress={() => props.navigation.navigate("Home")}
                style={{ backgroundColor: "#ffffff" }}
              >
                <Icon
                  type="AntDesign"
                  name="search1"
                  style={{
                    color:
                      props.navigation.state.index === 0 ? "#222222" : "#bbbbbb"
                  }}
                />
              </Button>
              <Button
                active={props.navigation.state.index === 1}
                onPress={() => props.navigation.navigate("Settings")}
                style={{ backgroundColor: "#ffffff" }}
              >
                <Icon
                  type="AntDesign"
                  name="setting"
                  style={{
                    color:
                      props.navigation.state.index === 1 ? "#222222" : "#bbbbbb"
                  }}
                />
              </Button>
            </FooterTab>
          </Footer>
        </StyleProvider>
      );
    }
  }
);

const styles = StyleSheet.create({
  footer: { backgroundColor: "#ffffff" }
});

const webStyles = StyleSheet.create({
  footer: { backgroundColor: "#ffffff", display: "none" }
});

export default createAppContainer(TabNavigator);
