import React, { Component } from "react";
import { View, StyleSheet, SafeAreaView, FlatList } from "react-native";
import {
  Container,
  Content,
  Footer,
  FooterTab,
  Button,
  Icon,
  Text,
  StyleProvider,
  Separator,
  List,
  ListItem,
  Left,
  Body,
  Right,
  SwipeRow
} from "native-base";

import { AppLoading } from "expo";
import Header from "../../components/header.tsx";
import getTheme from "../../native-base-theme/components";
import platform from "../../native-base-theme/variables/platform";

import { SwipeListView } from "react-native-swipe-list-view";
import * as FileSystem from "expo-file-system";
import * as Font from "expo-font";
import { Ionicons } from "@expo/vector-icons";

import Roboto from "../../node_modules/native-base/Fonts/Roboto.ttf";
import Roboto_medium from "../../node_modules/native-base/Fonts/Roboto_medium.ttf";

import Video from "../../models/video";

export default class VideoFilesScreen extends Component {
  static navigationOptions = ({ navigation }) => ({
    headerStyle: {
      elevation: 0,
      shadowOpacity: 0,
      borderBottomWidth: 0
    }
  });

  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      videoFiles: []
    };
  }

  componentWillMount() {
    this.getVideoFiles().then(files => {
      const videoFiles = files.map(fileName => {
        return { name: fileName };
      });
      this.setState({ videoFiles: videoFiles });
    });
  }

  async componentDidMount() {
    await Font.loadAsync({
      Roboto: Roboto,
      Roboto_medium: Roboto_medium,
      ...Ionicons.font
    });
    this.setState({ isReady: true });
  }

  async getVideoFiles() {
    const res = await FileSystem.getInfoAsync(
      `${FileSystem.documentDirectory}/videos`
    );
    let files = [];
    if (res.exists) {
      files = FileSystem.readDirectoryAsync(
        `${FileSystem.documentDirectory}/videos`
      );
    }
    return files;
  }

  _onPressDeleteButton = (videoFile, index) => {
    const fileURL = `${FileSystem.documentDirectory}/videos/${videoFile.name}`;
    FileSystem.deleteAsync(fileURL, { idempotent: true });
    const videoFiles = this.state.videoFiles.filter(item => {
      return !videoFile.name.includes(item.name);
    });
    this.setState({ videoFiles: videoFiles });
  };

  render() {
    if (!this.state.isReady) {
      return <AppLoading />;
    }

    return (
      <StyleProvider style={getTheme(platform)}>
        <SafeAreaView style={{ flex: 1 }}>
          <Header title="Video Files" />
          <Content>
            {this.state.videoFiles.map((videoFile, index) => (
              <SwipeRow
                key={videoFile.name}
                disableRightSwipe={true}
                rightOpenValue={-75}
                body={
                  <View style={styles.swipeRowBody}>
                    <Text>{videoFile.name}</Text>
                  </View>
                }
                right={
                  <Button
                    danger
                    onPress={this._onPressDeleteButton.bind(
                      this,
                      videoFile,
                      index
                    )}
                  >
                    <Text>Delete</Text>
                  </Button>
                }
              />
            ))}
          </Content>
        </SafeAreaView>
      </StyleProvider>
    );
  }
}

const styles = StyleSheet.create({
  headerMain: {
    fontSize: 17,
    textAlign: "center",
    fontWeight: "bold"
  },
  swipeRowBody: {
    paddingLeft: 30
  }
});
