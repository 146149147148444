import Dictation from "../components/dictation.tsx";
import React, { Component } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { Button, Icon, StyleProvider } from "native-base";

import getTheme from "../native-base-theme/components";

export default class DictationScreen extends Component {
  static navigationOptions = ({ navigation }) => ({
    headerTitle: (
      <View style={styles.headerWrapper}>
        <Text style={styles.headerMain} numberOfLines={1}>
          {navigation.getParam("title")}
        </Text>
        <Text style={styles.headerSub} numberOfLines={1}>
          Time left in video: 10mins
        </Text>
      </View>
    ),
    headerLeft: (
      <Button
        transparent
        onPress={() => {
          navigation.goBack();
        }}
        style={Platform.OS === "web" && webStyles.headerLeftButton}
      >
        <Icon name="arrow-back" style={{ color: "#222222" }} />
      </Button>
    ),
    headerTitleStyle: {
      justifyContent: "center"
    }
  });

  render() {
    const { navigation } = this.props;
    return (
      <StyleProvider style={getTheme()}>
        <Dictation
          videoID={navigation.getParam("videoID")}
          videoURL={navigation.getParam("videoURL")}
          transcriptURL={navigation.getParam("transcriptURL")}
        />
      </StyleProvider>
    );
  }
}

const styles = StyleSheet.create({
  headerWrapper: {
    width: "100%"
  },
  headerMain: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center"
  },
  headerSub: {
    fontSize: 15,
    textAlign: "center",
    color: "rgba(0,0,0,0.5)"
  }
});

const webStyles = StyleSheet.create({
  headerLeftButton: {
    marginTop: 10
  }
});
