import Home from "../components/home.tsx";
import Header from "../components/header.tsx";
import React, { Component } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  StatusBar,
  Platform
} from "react-native";
import {
  Container,
  Content,
  Footer,
  FooterTab,
  Button,
  Icon,
  Text,
  StyleProvider
} from "native-base";

import getTheme from "../native-base-theme/components";
import platform from "../native-base-theme/variables/platform";

export default class HomeScreen extends Component {
  static navigationOptions = ({ navigation }) => ({
    header: null
  });

  render() {
    return (
      <StyleProvider style={getTheme(platform)}>
        <SafeAreaView style={{ flex: 1 }}>
          <StatusBar barStyle="dark-content" backgroundColor="#ffffff" />
          <Container
            style={
              Platform.OS === "web" ? webStyles.container : styles.container
            }
          >
            <Header title="Home" />
            <Home navigation={this.props.navigation} />
          </Container>
        </SafeAreaView>
      </StyleProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: { paddingTop: 44 }
});

const webStyles = StyleSheet.create({
  container: {
    paddingTop: 44,
    maxWidth: 800,
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto"
  }
});
