import * as FileSystem from "expo-file-system";

export default class Video {
  public id: string;
  public name: string;
  public channelName: string;
  public imageURL: string;
  public videoURL: string;
  public transcriptURL: string;

  private directoryPath: string = `${FileSystem.documentDirectory}/videos`;

  constructor(
    id: string,
    name: string,
    channelName: string,
    imageURL: string,
    videoURL: string,
    transcriptURL: string
  ) {
    this.id = id;
    this.name = name;
    this.channelName = channelName;
    this.imageURL = imageURL;
    this.videoURL = videoURL;
    this.transcriptURL = transcriptURL;
  }

  static fetchJSON(): object {
    return [
      {
        id: "16p9YRF0l-g",
        name: "How to build your creative confidence | David Kelley",
        channelName: "TED",
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/16p9YRF0l-g.jpg?alt=media&token=99ab1d57-a34b-4ef7-9edf-e7108ac7b377",
        videoURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/DavidKelley_2012-480p.mp4?alt=media&token=014c44f6-6e4a-4be7-ad47-5908ea1b754e",
        transcriptURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/16p9YRF0l-g.json?alt=media&token=fce9bc3a-9e8a-4118-a0da-4a5316e66450"
      },
      {
        id: "5XD2kNopsUs",
        name: "Why work doesn't happen at work | Jason Fried",
        channelName: "TED",
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/5XD2kNopsUs.jpg?alt=media&token=29693d74-8ca8-4037-9959-6cd6b296ff08",
        videoURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/JasonFried_2010X-480p.mp4?alt=media&token=06d43e7c-1c24-49e1-9e3d-688afce50e8a",
        transcriptURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-3afa6.appspot.com/o/1014.json?alt=media&token=e581d568-c365-496f-a954-fe1c700bae21"
      },
      {
        id: "elonmusk-1695",
        name: "Elon Musk: The mind behind Tesla, SpaceX, SolarCity ...",
        channelName: "TED",
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-f0188.appspot.com/o/2534551796ee0a2638b462ce82e33b65091b1d42_1600x1200.jpg?alt=media&token=9f3ec67c-b656-4277-9865-73c61e8d69d4",
        videoURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-f0188.appspot.com/o/ElonMusk_2013-480p.mp4?alt=media&token=692e86ed-6859-49bb-8df9-e8084b37af8d",
        transcriptURL:
          "https://firebasestorage.googleapis.com/v0/b/dict-f0188.appspot.com/o/1695.json?alt=media&token=cfff2e05-efb3-4b9b-bbc4-f22134084a3a"
      }
    ];
  }

  get mp4FilePath(): string {
    return `${this.directoryPath}/${this.id}.mp4`;
  }

  async getMp4InfoAsync(): object {
    return await FileSystem.getInfoAsync(this.mp4FilePath);
  }

  async checkMp4FileDownloadedAsync(): bool {
    const res = await this.getMp4InfoAsync();
    return res.exists;
  }
}
