import React, { Component } from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import {
  Container,
  Content,
  Footer,
  FooterTab,
  Button,
  Icon,
  Text,
  StyleProvider,
  Separator,
  ListItem,
  Left,
  Right
} from "native-base";

import Header from "../components/header.tsx";

import getTheme from "../native-base-theme/components";
import platform from "../native-base-theme/variables/platform";

export default class SettingsScreen extends Component {
  static navigationOptions = ({ navigation }) => ({
    header: null
  });

  render() {
    return (
      <StyleProvider style={getTheme(platform)}>
        <SafeAreaView style={{ flex: 1 }}>
          <View style={{ paddingTop: 44 }}>
            <Header title="Settings" />
          </View>
          <Content>
            <ListItem
              onPress={() => {
                this.props.navigation.navigate("VideoFiles", {});
              }}
            >
              <Left>
                <Text>Video Files</Text>
              </Left>
              <Right>
                <Icon name="arrow-forward" />
              </Right>
            </ListItem>
          </Content>
        </SafeAreaView>
      </StyleProvider>
    );
  }
}

const styles = StyleSheet.create({
  headerMain: {
    fontSize: 17,
    textAlign: "center",
    fontWeight: "bold"
  }
});
