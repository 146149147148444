import React, { Component } from "react";
import {
  View,
  FlatList,
  Image,
  TouchableHighlight,
  StyleSheet,
  AppState,
  AsyncStorage,
  Platform
} from "react-native";
import {
  Container,
  Header,
  Content,
  List,
  ListItem,
  Text,
  Left,
  Body,
  Right,
  Title,
  Thumbnail,
  Icon
} from "native-base";
import { ProgressCircle } from "react-native-svg-charts";
import * as FileSystem from "expo-file-system";
import Video from "../models/video";

import { NavigationEvents } from "react-navigation";

let downloadResumable;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      videoFiles: [],
      downloadProgress: 0.0,
      downloadingVideoID: null
    };
  }

  componentDidMount() {
    this.updateVideoFiles();
    this.setState({ videos: Video.fetchJSON() });
    AppState.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    AppState.removeEventListener("change", this._handleAppStateChange);
  }

  donwloadingVideoFile = () => {
    return this.state.downloadingVideoID !== null;
  };

  resumeableDownloadFileExist = async () => {
    const downloadingVideoID = await AsyncStorage.getItem("resumableVideoID");
    return !!downloadingVideoID;
  };

  _handleAppStateChange = async nextAppState => {
    if (nextAppState == "background") {
      await this.getInactive();
    } else if (nextAppState == "active") {
      await this.getActive();
    }
  };

  getInactive = async () => {
    if (this.donwloadingVideoFile()) {
      const videoID = this.state.downloadingVideoID;
      try {
        await downloadResumable.pauseAsync();
        await AsyncStorage.setItem("resumableVideoID", videoID);
        await AsyncStorage.setItem(
          `resumable-downloading-file-${videoID}`,
          JSON.stringify(downloadResumable.savable())
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  getActive = async () => {
    const resumableVideoID = await AsyncStorage.getItem("resumableVideoID");
    if (resumableVideoID) {
      this.setState({ downloadingVideoID: resumableVideoID });
      const downloadSnapshotJson = await AsyncStorage.getItem(
        `resumable-downloading-file-${this.state.downloadingVideoID}`
      );
      const downloadSnapshot = JSON.parse(downloadSnapshotJson);

      downloadResumable = null;
      downloadResumable = new FileSystem.DownloadResumable(
        downloadSnapshot.url,
        downloadSnapshot.fileUri,
        downloadSnapshot.options,
        this.downloadVideoProgress,
        downloadSnapshot.resumeData
      );

      try {
        await downloadResumable.resumeAsync().catch(e => {
          console.log(e);
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  updateVideoFiles() {
    if (Platform.OS !== "web") {
      this.getVideoFiles().then(files => {
        const videoFiles = files.map(fileName => {
          return { name: fileName };
        });
        this.setState({ videoFiles: videoFiles });
      });
    }
  }

  async getVideoFiles() {
    const res = await FileSystem.getInfoAsync(
      `${FileSystem.documentDirectory}/videos`
    );
    let files = [];
    if (res.exists) {
      files = await FileSystem.readDirectoryAsync(
        `${FileSystem.documentDirectory}/videos`
      );
    }
    return files;
  }

  isDownloadedVideo = video => {
    if (this.state.videoFiles.length > 0) {
      const a = this.state.videoFiles[0].name == `${video.id}.mp4`;
    }

    const file = this.state.videoFiles.find(videoFile => {
      return videoFile.name == `${video.id}.mp4`;
    });

    return Boolean(file);
  };

  _keyExtractor = (item, index) => item.id;

  _renderItem = ({ item }) => {
    const video = new Video(
      item.id,
      item.name,
      item.channelName,
      item.imageURL,
      item.videoURL,
      item.transcriptURL
    );

    return (
      <ListItem
        thumbnail
        onPress={() => {
          this.onPress(video);
        }}
      >
        <Left>
          <Image
            style={{ width: 120, height: 70 }}
            source={{ uri: video.imageURL }}
          />
          {(this.isDownloadingVideo(video) ||
            !this.isDownloadedVideo(video)) && (
            <View style={styles.imageMask} />
          )}
          {this.isDownloadingVideo(video) && (
            <ProgressCircle
              progress={this.state.downloadProgress}
              progressColor="#ffffff"
              backgroundColor="#aaaaaa"
              strokeWidth={5}
              style={styles.loadingCircle}
            />
          )}
          {!this.isDownloadingVideo(video) && !this.isDownloadedVideo(video) && (
            <View style={styles.cloudDownloadIconWrapper}>
              <Icon
                name="download-cloud"
                type="Feather"
                style={{ color: "#ffffff" }}
              />
            </View>
          )}
        </Left>
        <Body style={Platform.OS === "web" ? webStyles.listBody : {}}>
          <View style={Platform.OS === "web" ? webStyles.listBodyItem : {}}>
            <Text>{video.channelName}</Text>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>
              {video.name}
            </Text>
          </View>
        </Body>
        <Right />
      </ListItem>
    );
  };

  saveResumableData = async videoID => {
    try {
      await downloadResumable.pauseAsync();
      await AsyncStorage.setItem("resumableVideoID", videoID);
      await AsyncStorage.setItem(
        `resumable-downloading-file-${videoID}`,
        JSON.stringify(downloadResumable.savable())
      );
    } catch (e) {
      console.error(e);
    }
  };

  resumeDownloadingVideo = async videoID => {
    this.setState({ downloadingVideoID: videoID });
    const downloadSnapshotJson = await AsyncStorage.getItem(
      `resumable-downloading-file-${videoID}`
    );
    const downloadSnapshot = JSON.parse(downloadSnapshotJson);
    downloadResumable = new FileSystem.DownloadResumable(
      downloadSnapshot.url,
      downloadSnapshot.fileUri,
      downloadSnapshot.options,
      this.downloadVideoProgress,
      downloadSnapshot.resumeData
    );

    try {
      await downloadResumable.resumeAsync().catch(e => {
        console.log(e);
      });
    } catch (e) {
      console.error(e);
    }
  };

  onPress = async video => {
    if (this.isDownloadedVideo(video) || Platform.OS === "web") {
      return this.navigateToDictationScreen(video);
    }

    if (this.donwloadingVideoFile()) {
      await this.saveResumableData(this.state.downloadingVideoID);
      await this.setState({ downloadProgress: 0 });
    }

    if (this.isDownloadingVideo(video)) {
      this.setState({ downloadingVideoID: null });
      return;
    } else {
      const resumableVideoFile = await AsyncStorage.getItem(
        `resumable-downloading-file-${video.id}`
      );

      if (resumableVideoFile) {
        await this.resumeDownloadingVideo(video.id);
      } else {
        await this.downloadVideo(video);
      }
    }
  };

  navigateToDictationScreen = video => {
    this.props.navigation.navigate("Dictation", {
      videoID: video.id,
      title: video.name,
      videoURL: video.videoURL,
      transcriptURL: video.transcriptURL
    });
  };

  isDownloadingVideo = video => {
    return this.state.downloadingVideoID === video.id;
  };

  async checkVideosDirectory() {
    const res = await FileSystem.getInfoAsync(
      `${FileSystem.documentDirectory}/videos`
    );
    if (!res.exists) {
      await FileSystem.makeDirectoryAsync(
        `${FileSystem.documentDirectory}/videos`
      );
    } else {
      FileSystem.readDirectoryAsync(`${FileSystem.documentDirectory}/videos`);
    }
  }

  downloadVideo = async video => {
    await this.checkVideosDirectory();
    downloadResumable = FileSystem.createDownloadResumable(
      video.videoURL,
      `${FileSystem.documentDirectory}/videos/${video.id}.mp4`,
      {},
      this.downloadVideoProgress
    );
    this.setState({ downloadingVideoID: video.id });
    try {
      await downloadResumable.downloadAsync().catch(e => {
        console.log("downloadAsync error");
        console.log(e);
      });
      this.updateVideoFiles();
      this.setState({ downloadingVideoID: null });
    } catch (e) {
      console.error(e);
    }
  };

  downloadVideoProgress = downloadProgress => {
    const progress =
      downloadProgress.totalBytesWritten /
      downloadProgress.totalBytesExpectedToWrite;
    this.setState({ downloadProgress: progress });

    if (progress == 1) {
      this.clearDownloadStates();
    }
  };

  clearDownloadStates = async () => {
    await AsyncStorage.removeItem("resumableVideoID");
    await AsyncStorage.removeItem(
      `resumable-downloading-file-${this.state.downloadingVideoID}`
    );
    setTimeout(() => {
      this.updateVideoFiles();
    }, 1000);
    await this.setState({ downloadingVideoID: null, downloadProgress: 0 });
  };

  willFocus = payload => {
    this.updateVideoFiles();
  };

  render() {
    return (
      <Content>
        <NavigationEvents onWillFocus={this.willFocus} />
        <FlatList
          data={this.state.videos}
          extraData={this.state}
          keyExtractor={this._keyExtractor}
          renderItem={this._renderItem}
        />
      </Content>
    );
  }
}

const styles = StyleSheet.create({
  imageMask: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 120,
    height: 70,
    backgroundColor: "rgba(0, 0, 0, 0.3)"
  },
  loadingCircle: {
    position: "absolute",
    left: 47.5,
    top: 22.5,
    width: 32, // probably bug of react-native-svg-charts. However couldn't find the cause of it.
    height: 30
  },
  cloudDownloadIconWrapper: {
    position: "absolute",
    right: 5,
    top: 5,
    width: 20,
    height: 20
  },
  listBodyItemText: {
    color: "#979797",
    fontSize: 14
  }
});

const webStyles = StyleSheet.create({
  listBody: { marginLeft: 0 },
  listBodyItem: {
    marginLeft: 120,
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5
  },
  listBodyItemText: {
    color: "#979797",
    fontSize: 14
  }
});
