export default class Transcript {
    public time: number;
    public text: string;

    private baseSecond: number = 12
    private baseMilliSecond: number = 12000

    constructor(time: number, text: text) {
      this.time = time;
      this.text = text;
    }

    get actualTime(): number {
      return this.baseMilliSecond + this.time
    }

    get timeMMSS(): string {
      const second: number = this.time / 1000;
      const transcriptSeconds: number = this.baseSecond + second;

      let decoratedMinutes: number = parseInt(transcriptSeconds / 60);
      if (decoratedMinutes < 10) {
        decoratedMinutes = "0" + decoratedMinutes;
      }

      let decoratedSeconds: number = parseInt(transcriptSeconds % 60);
      if (decoratedSeconds < 10) {
        decoratedSeconds = "0" + decoratedSeconds;
      }

      return `${decoratedMinutes}:${decoratedSeconds}`
    }
}
