import React, { Component, PureComponent } from "react";
import {
  View,
  FlatList,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  Dimensions
} from "react-native";
import {
  Container,
  Content,
  Footer,
  Text,
  Textarea,
  Form,
  Button,
  Card,
  CardItem,
  Left,
  Body,
  Right,
  ListItem,
  Icon
} from "native-base";

import { Video, Audio } from "expo-av";

import Transcript from "../models/transcript";
import * as FileSystem from "expo-file-system";
import PlaybackRateIcon from "./playback_rate_icon";

let playbackObject = null;
let flatListRef = null;
let transcriptCheckerID = null;

const playbackRates = [0.5, 0.75, 1.0, 1.25, 1.5];
const playbackRateTexts = ["0.50", "0.75", "1.00", "1.25", "1.50"];

const deviceHeight = Dimensions.get("window").height;
const deviceWidth = Dimensions.get("window").width;

// PureComponent: https://medium.com/@gauravsapiens/react-native-making-your-app-fast-again-d00d02ee2b2a
export default class Dictation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      videoPlaying: true,
      repeating: false,
      transcripts: [],
      currentTranscriptIndex: 0,
      videoURL: "",
      playbackRateIndex: 2
    };
  }

  componentWillMount() {
    this.fetchTranscripts().then(transcripts =>
      this.setState({ transcripts: transcripts })
    );
  }

  componentDidMount() {
    this.setupVideo();
    this.setupTranscriptChecker();
  }

  setupVideo() {
    if (Platform.OS === "web") {
      // playbackObject.loadAsync({ uri: this.props.videoURL }).then(() => {
      //   playbackObject.playAsync();
      // });
    } else {
      const fileUri = `${FileSystem.documentDirectory}/videos/${this.props.videoID}.mp4`;
      FileSystem.getInfoAsync(fileUri).then(res => {
        if (res.exists) {
          playbackObject
            .loadAsync({ uri: res.uri })
            .then(() => playbackObject.playAsync());
        } else {
          alert("Video file not found");
          console.log(res);
        }
      });
    }
  }

  setupTranscriptChecker = () => {
    transcriptCheckerID = setInterval(this.checkTranscript, 500);
  };

  checkTranscript = async () => {
    const currentTranscript = this.state.transcripts[
      this.state.currentTranscriptIndex
    ];
    let nextTranscriptIndex = this.state.currentTranscriptIndex + 1;
    // On the last transcript
    if (nextTranscriptIndex == this.state.transcripts.length) {
      nextTranscriptIndex = this.state.currentTranscriptIndex;
    }

    const nextTranscript = this.state.transcripts[nextTranscriptIndex];
    if (!nextTranscript) {
      return;
    }

    const scrollTranscriptIndex =
      nextTranscriptIndex == 0 ? nextTranscriptIndex : nextTranscriptIndex - 1;

    const status = await playbackObject.getStatusAsync();
    if (status.positionMillis) {
      const diff: number = nextTranscript.actualTime - status.positionMillis;
      if (diff <= 500) {
        if (this.state.repeating) {
          playbackObject.playFromPositionAsync(currentTranscript.actualTime, {
            toleranceMillisBefore: 0,
            toleranceMillisAfter: 0
          });
        } else {
          this.setState({ currentTranscriptIndex: nextTranscriptIndex }, () => {
            setTimeout(() => {
              flatListRef.scrollToIndex({
                animated: true,
                index: scrollTranscriptIndex
              });
            }, 100);
          });
        }
      }
    }
  };

  componentWillUnmount() {
    clearInterval(transcriptCheckerID);
  }

  async fetchTranscripts() {
    const response = await fetch(this.props.transcriptURL);
    const data = await response.json();
    const cues = await data.paragraphs.flatMap(paragraph => paragraph["cues"]);
    const transcripts = await cues.map(
      cue => new Transcript(cue.time, cue.text)
    );
    return transcripts;
  }

  _keyExtractor = (item, index) => `${index}`;

  _pressListItem = (item, index) => {
    this.setState({ currentTranscriptIndex: index }, () => {
      const scrollTranscriptIndex = index == 0 ? index : index - 1;
      setTimeout(() => {
        flatListRef.scrollToIndex({
          animated: true,
          index: scrollTranscriptIndex
        });
        playbackObject.playFromPositionAsync(item.actualTime, {
          toleranceMillisBefore: 0,
          toleranceMillisAfter: 0
        });
      });
    });
  };

  _handleVideoRef = component => {
    playbackObject = component;
  };

  _handleFlatListRef = ref => {
    flatListRef = ref;
  };

  pressPlayButton = () => {
    if (this.state.videoPlaying) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  };

  pressRepeatButton = () => {
    if (this.state.repeating) {
      this.setState({ repeating: false });
    } else {
      this.setState({ repeating: true });
    }
  };

  pressPlaybackRateButton = () => {
    let nextPlaybackRateIndex;
    const currentPlaybackRateIndex = this.state.playbackRateIndex;
    if (currentPlaybackRateIndex + 1 == playbackRates.length) {
      nextPlaybackRateIndex = 0;
    } else {
      nextPlaybackRateIndex = currentPlaybackRateIndex + 1;
    }

    this.setState({ playbackRateIndex: nextPlaybackRateIndex });
    playbackObject.setRateAsync(
      playbackRates[nextPlaybackRateIndex],
      true,
      Audio.PitchCorrectionQuality.High
    );
  };

  playVideo = () => {
    playbackObject.playAsync();
    this.setState({ videoPlaying: true });
  };

  pauseVideo = () => {
    playbackObject.pauseAsync();
    this.setState({ videoPlaying: false });
  };

  _renderItem = ({ item, index }) => {
    const active = this.state.currentTranscriptIndex == index;
    return (
      <ListItem thumbnail onPress={this._pressListItem.bind(this, item, index)}>
        <Left style={{ flex: 0.15, flexDirection: "column" }}>
          <Text style={styles.listSequenceNumberText}>{`${index + 1}`}</Text>
          <Text
            style={
              active ? styles.activeListStartAtText : styles.listStartAtText
            }
          >
            {item.timeMMSS}
          </Text>
        </Left>
        <Body style={active ? styles.activeListItemBody : styles.listItemBody}>
          {Platform.OS === "ios" ? (
            <TextInput
              value={item.text}
              editable={false}
              style={active ? styles.activeListText : styles.listText}
              pointerEvents={active ? "auto" : "none"}
              multiline
              autoGrow
            />
          ) : (
            <Text
              selectable={true}
              style={active ? styles.activeListText : styles.listText}
            >
              {item.text}
            </Text>
          )}
        </Body>
      </ListItem>
    );
  };

  render() {
    return (
      <Container
        style={Platform.OS === "web" ? webStyles.container : styles.container}
      >
        <FlatList
          ref={this._handleFlatListRef}
          data={this.state.transcripts}
          keyExtractor={this._keyExtractor}
          renderItem={this._renderItem}
          ListFooterComponent={
            <View
              style={
                Platform.OS === "web" ? webStyles.listFooter : styles.listFooter
              }
            ></View>
          }
        />
        <View
          style={
            Platform.OS === "web"
              ? webStyles.videoContainer
              : styles.videoContainer
          }
        >
          <Video
            source={{ uri: Platform.OS === "web" && this.props.videoURL }}
            ref={this._handleVideoRef}
            volume={1.0}
            isMuted={false}
            shouldPlay
            resizeMode={Video.RESIZE_MODE_COVER}
            style={Platform.OS === "web" ? webStyles.video : styles.video}
          />
        </View>
        <View
          style={
            Platform.OS === "web"
              ? webStyles.playButtonWrapper
              : styles.playButtonWrapper
          }
        >
          <Button transparent onPress={this.pressPlayButton}>
            <Icon
              type="Ionicons"
              name={this.state.videoPlaying ? "pause" : "play"}
              size={30}
              style={Platform.OS === "web" ? webStyles.icon : styles.icon}
            />
          </Button>
        </View>
        <View
          style={
            Platform.OS === "web"
              ? webStyles.repeatButtonWrapper
              : styles.repeatButtonWrapper
          }
        >
          <Button transparent onPress={this.pressRepeatButton}>
            <Icon
              type="Ionicons"
              name="repeat"
              size={30}
              style={
                Platform.OS === "web"
                  ? this.state.repeating
                    ? webStyles.activeRepeatIcon
                    : webStyles.icon
                  : this.state.repeating
                  ? styles.activeRepeatIcon
                  : styles.icon
              }
            />
          </Button>
        </View>
        <View
          style={
            Platform.OS === "web"
              ? webStyles.playbackRateButtonWrapper
              : styles.playbackRateButtonWrapper
          }
        >
          <Button transparent onPress={this.pressPlaybackRateButton}>
            <Text
              style={
                Platform.OS === "web"
                  ? webStyles.playbackRateButtonText
                  : styles.playbackRateButtonText
              }
            >
              {`${playbackRateTexts[this.state.playbackRateIndex]}x`}
            </Text>
          </Button>
        </View>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  content: {
    flex: 1
  },
  listFooter: { height: 150, backgroundColor: "#ffffff" },
  activeListItem: {},
  listItem: {},
  activeListItemBody: {
    flex: 0.85,
    paddingRight: 15,
    paddingTop: 25,
    paddingBottom: 25
  },
  listItemBody: {
    flex: 0.85,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15
  },
  listSequenceNumberText: {
    color: "#000000",
    fontSize: 17,
    fontWeight: "bold",
    width: 40,
    textAlign: "center",
    marginBottom: 5
  },
  listStartAtText: {
    color: "#000000",
    fontSize: 13,
    fontWeight: "normal",
    width: 40,
    textAlign: "center"
  },
  activeListStartAtText: {
    color: "#000000",
    fontSize: 13,
    fontWeight: "bold",
    width: 40,
    textAlign: "center"
  },
  listText: {
    fontWeight: "normal",
    fontSize: 17
  },
  activeListText: {
    fontWeight: "bold",
    fontSize: 17
  },
  videoContainer: {
    position: "absolute",
    backgroundColor: "#222222",
    left: 15,
    bottom: 30,
    width: 120,
    height: 70,
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.5,
    elevation: 5
  },
  video: {
    width: 120,
    height: 70
  },
  playButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 145,
    bottom: 30,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25
  },
  repeatButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 80,
    bottom: 30,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25
  },
  playbackRateButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 15,
    bottom: 30,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25,
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  playbackRateButtonText: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: 14,
    backgroundColor: "transparent",
    marginLeft: -10,
    marginRight: -10
  },
  icon: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 25,
    color: "#000000",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 30,
    height: 30,
    backgroundColor: "white"
  },
  activeRepeatIcon: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 25,
    color: "#007AFF",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 30,
    height: 30,
    backgroundColor: "white"
  },
  playbackRateIcon: {
    marginLeft: 0,
    marginTop: 5,
    color: "#000000",
    textAlign: "center",
    justifyContent: "center",
    height: "50%",
    width: "100%",
    backgroundColor: "red"
  }
});

const videoWidth = deviceWidth * 0.32;
const videoHeight = videoWidth * 0.58;

const controlButtonSize = deviceWidth * 0.13;

const webStyles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 800,
    marginTop: 15,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto"
  },
  videoContainer: {
    position: "absolute",
    backgroundColor: "#222222",
    left: 15,
    bottom: 65,
    width: videoWidth,
    height: videoHeight,
    maxWidth: 250,
    maxHeight: 142,
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.5,
    elevation: 5
  },
  listFooter: { height: videoHeight, backgroundColor: "#ffffff" },
  video: {
    width: videoWidth,
    height: videoHeight,
    maxWidth: 250,
    maxHeight: 142
  },
  playButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 145,
    bottom: 75,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25
  },
  repeatButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 80,
    bottom: 75,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25
  },
  playbackRateButtonWrapper: {
    position: "absolute",
    backgroundColor: "white",
    right: 15,
    bottom: 75,
    width: 50,
    height: 50,
    borderRadius: 10,
    elevation: 5, // for Android shadow https://stackoverflow.com/a/41514731
    shadowColor: "#000000",
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.25,
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  playbackRateButtonText: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: 14,
    backgroundColor: "transparent",
    marginLeft: -10,
    marginRight: -10,
    fontFamily: "Helvetica Neue"
  },
  icon: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 25,
    color: "#000000",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 30,
    height: 30,
    backgroundColor: "white"
  },
  activeRepeatIcon: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 25,
    color: "#007AFF",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 30,
    height: 30,
    backgroundColor: "white"
  },
  playbackRateIcon: {
    marginLeft: 0,
    marginTop: 5,
    color: "#000000",
    textAlign: "center",
    justifyContent: "center",
    height: "50%",
    width: "100%",
    backgroundColor: "red"
  }
});
